import styles from './button.module.scss';
import cx from 'classnames';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import type {FC} from 'react';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  readonly color?: 'primary' | 'accent';
  readonly variant?: 'raised' | 'stroked';
  readonly fullWidth?: boolean;
}

const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  ({className, color, variant, fullWidth, ...props}, ref) => (
    <button
      {...props}
      ref={ref}
      className={cx(
        styles.basic,
        {[styles.primary]: (!color || color === 'primary') && variant !== 'stroked'},
        {[styles.accent]: color === 'accent' && variant !== 'stroked'},
        {[styles.stroked]: variant === 'stroked'},
        {[styles.primaryStroked]: variant === 'stroked' && color === 'primary'},
        {[styles.accentStroked]: variant === 'stroked' && color === 'accent'},
        {[styles.fullWidth]: fullWidth},
        className,
      )}
    />
  ),
);

export default Button;
