import Link from 'next/link';
import {Fragment, useEffect, useState} from 'react';
import Category from '@/models/category';
import axios from '@/utils/axios';
import type {FC} from 'react';

interface Props {
  readonly limit?: number;
}

const CategoriesList: FC<Props> = ({limit = 0}) => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    axios
      .get(`/categories/all?limit=${limit}`)
      .then((res) => {
        if (Array.isArray(res.data)) {
          setCategories(res.data.map(Category.from));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Fragment>
      {categories.map((category) => (
        <div key={category.id} className="w-full md:w-1/3 lg:w-3/12 my-2 text-center p-4">
          <Link href={`/category/${category.id}`} passHref>
            <a>
              <div className="border border-accent-A500 rounded-lg hover:shadow-lg overflow-hidden">
                <div className="bg-accent-500 p-6">
                  <img src={category.logo_url} alt={category.name} className="block w-1/2 m-auto" />
                </div>
                <div className="bg-light-50 p-3">
                  <p className="text-base text-gray-500 font-semibold truncate">{category.name}</p>
                </div>
              </div>
            </a>
          </Link>
        </div>
      ))}
    </Fragment>
  );
};

export default CategoriesList;
