import ReactModal from 'react-modal';
import {FiX} from 'react-icons/fi';
import {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import type {ReactNode, FC} from 'react';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90vh',
    maxWidth: '90vw',
    overflowH: 'auto',
  },
};

interface ModalProps {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly children: ReactNode;
}

const Modal: FC<ModalProps> = ({open: isInitiallyOpen, onClose, children}) => {
  const [open, setOpen] = useState(isInitiallyOpen);

  useEffect(() => {
    setOpen(isInitiallyOpen);
  }, [isInitiallyOpen, setOpen]);

  const close = () => {
    setOpen(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <motion.div animate={{scale: 2}} transition={{duration: 0.5}}>
      <ReactModal isOpen={open} onRequestClose={close} style={customStyles} ariaHideApp={false}>
        <button
          className="bg-red-400 hover:bg-red-500 p-1 text-white rounded-full shadow-xl float-right mb-4"
          onClick={close}
        >
          <FiX />
        </button>
        {children}
      </ReactModal>
    </motion.div>
  );
};

export default Modal;
