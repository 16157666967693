export default class Category {
  public readonly id: number;
  public readonly name: string;
  public readonly logo_url: string;

  constructor(category: Partial<Category>) {
    this.id = category.id ?? 0;
    this.name = category.name ?? '';
    this.logo_url = category.logo_url ?? '';
  }

  public static from(category: Partial<Category>): Category {
    return {...new Category(category)};
  }
}
