import Modal from '@/components/modal';
import Button from '@/components/button';
import Link from 'next/link';
import MainLayout from '@/components/layouts/main-layout';
import CategoriesList from '@/components/pages/home/categories-list';
import {FiPlay, FiPlayCircle, FiUser, FiUsers} from 'react-icons/fi';
import {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import type {WithAuthProps} from '@/contexts/auth-context';
import {withAuth} from '@/contexts/auth-context';
import {UserRole} from '@/models/user';
import type {WithRouterProps} from 'next/dist/client/with-router';
import {withRouter} from 'next/router';

type Props = WithAuthProps & WithRouterProps;

interface State {
  readonly videoOverlayVisible: boolean;
}

class HomePage extends Component<Props, State> {
  public readonly state: State = {videoOverlayVisible: false};

  public async componentDidMount() {
    const {user} = this.props.session;
    if (user?.role === UserRole.Provider) {
      await this.props.router.push({pathname: `/provider/${user.username}`});
    }
  }

  public render(): JSX.Element {
    const {videoOverlayVisible} = this.state;
    return (
      <MainLayout metadata={{title: 'Soubul'}}>
        <section id="intro-section" className="text-white w-full bg-accent-500">
          <div className="flex flex-wrap items-center container mx-auto pb-12 lg:py-24">
            <div className="w-full lg:w-1/2 lg:pr-28 my-12">
              <h1 className="text-2xl lg:text-3xl font-semibold">
                <span className="font-bold text-primary-300 dark:text-primary-50">
                  <FormattedMessage id="words.soubul" />,
                </span>
                <span>&nbsp;</span>
                <FormattedMessage id="words.create_your_future" />
              </h1>
              <p className="my-4">
                <FormattedMessage id="content.about" />
              </p>
              <div className="flex">
                <Link href="/sign-up" passHref>
                  <Button>
                    <FormattedMessage id="words.get_started" />
                  </Button>
                </Link>
                <Button className="mx-4" variant="stroked" onClick={this.openVideoModal}>
                  <FormattedMessage id="words.watch_demo" />
                  <FiPlayCircle className="ltr:ml-2 rtl:mr-2" />
                </Button>
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <div className="relative">
                <img
                  className="block w-full rounded-lg border border-light-100 dark:border-light-800 shadow-2xl"
                  src="assets/intro-thumbnail.png"
                  alt="Soubul Intro"
                />
                <Modal open={videoOverlayVisible} onClose={this.onCloseVideoModal}>
                  <video className="block w-full" autoPlay controls>
                    <source src="assets/intro.mp4" />
                  </video>
                </Modal>
                <div className="absolute inset-0 rounded-lg flex items-center justify-center bg-black bg-opacity-25">
                  <button
                    className="bg-primary-200 hover:bg-primary-300 p-4 text-white rounded-full shadow-xl"
                    onClick={this.openVideoModal}
                  >
                    <FiPlay />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-wrap items-center container mx-auto py-16">
          <div className="w-full my-4">
            <p className="text-lg text-gray-500 font-semibold"><FormattedMessage id="words.get_teacher_trainer_mentor"/></p>
          </div>
          <CategoriesList limit={8} />
          <div className="w-full my-6 text-center">
            <p className="text-sm text-gray-500">
              <span><FormattedMessage id="words.what_you_are_looking_for"/></span>
              <span>&nbsp;</span>
              <Link href="/category/all" passHref>
                <a className="text-blue-500"><FormattedMessage id="words.see_all_categories"/></a>
              </Link>
            </p>
          </div>
        </section>

        <section className="flex flex-wrap items-center w-full text-center py-24 bg-gray-200">
          <div className="w-full my-6">
            <h1 className="font-semibold text-2xl">
              <FormattedMessage id="pages.page.get_started" />
            </h1>
          </div>
          <div className="w-full my-6">
            <Link href="/sign-up" passHref>
              <Button className="m-4">
                <FiUsers />
                <span className="ltr:ml-2 rtl:mr-2">
                  <FormattedMessage id="words.start_as_student" />
                </span>
              </Button>
            </Link>
            <Link href="/sign-up" passHref>
              <Button className="m-4" color="accent">
                <FiUser />
                <span className="ltr:ml-2 rtl:mr-2">
                  <FormattedMessage id="words.start_as_provider" />
                </span>
              </Button>
            </Link>
          </div>
        </section>
      </MainLayout>
    );
  }

  /** @internal */
  private openVideoModal = () => {
    this.setState({videoOverlayVisible: true});
  };

  /** @internal */
  private onCloseVideoModal = () => {
    this.setState({videoOverlayVisible: false});
  };
}

export default withAuth(withRouter(HomePage));
