export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER',
  Provider = 'SERVICE_PROVIDER',
}

export default class User {
  public readonly id: number;
  public readonly name: string;
  public readonly email: string;
  public readonly username: string;
  public readonly phone_number: string;
  public readonly avatar_url: string;
  public readonly email_confirmed: boolean;
  public readonly phone_number_confirmed: boolean;
  public readonly role: UserRole;
  public readonly signup_completed: boolean;
  public readonly creation_date: string;
  public readonly access_token: string;
  public readonly has_provider_account: boolean;
  public readonly has_parent_account: boolean;

  // computed properties...

  public readonly firstName: string;

  constructor(user: Partial<User>) {
    this.id = user.id ?? 0;
    this.name = user.name ?? '';
    this.email = user.email ?? '';
    this.username = user.username ?? '';
    this.avatar_url = user.avatar_url ?? '';
    this.phone_number = user.phone_number ?? '';
    this.email_confirmed = user.email_confirmed ?? false;
    this.phone_number_confirmed = user.phone_number_confirmed ?? false;
    this.role = user.role ?? UserRole.User;
    this.signup_completed = user.signup_completed ?? false;
    this.creation_date = user.creation_date ?? '';
    this.access_token = user.access_token ?? '';
    this.has_provider_account = user.has_provider_account ?? false;
    this.has_parent_account = user.has_parent_account ?? false;
    this.firstName = this.name.split(' ').shift() ?? '';
  }

  public static from(user: Partial<User>): User {
    return {...new User(user)};
  }
}
